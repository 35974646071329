@import "../styles/variables";

.wrapper {
  display: flex;
  width: 100%;
  height: 744px;
  background-color: $accent;
  overflow: hidden;

  .leftbox {
    background-color: $almostBlack;
    flex: 1;
    height: 100%;
  }

  .text {
    padding: 60px;
  }

  .rightbox {
    position: relative;
    max-height: 100%;
    flex: 1;
  }

  .doctor {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform-origin: bottom center;
  }

  h2 {
    font-size: 50px;
    color: white;
    letter-spacing: 0;
    margin: 30px 0;
    font-weight: 900;
    line-height: 1.2;
  }

  p {
    font-size: 20px;
    color: $accent;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 50px;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1023px) {
  .wrapper {
    flex-direction: column-reverse;
    height: unset;

    .leftbox {
      background-color: $almostBlack;
      flex: 1;
      height: 100%;
    }

    .text {
      padding: 60px 30px;
    }

    .rightbox {
      position: relative;
      max-height: 100%;
      flex: 1;
      max-width: unset;
      min-width: unset;
    }

    .doctor {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      transform-origin: bottom center;
      padding-bottom: 100%;
    }

    h2 {
      font-size: 35px;
    }

    p {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 50px;
      padding-right: 0;
    }
  }
}
