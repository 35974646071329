@import "../../styles/variables";

.callout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 0 auto;
  overflow: hidden;

  h2 {
    font-size: 50px;
    font-weight: 800;
    text-align: center;
    padding: 20px 0;
  }

  p {
    width: 450px;
    height: 195px;
    color: $darkGrey;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
  }
}

@media screen and (max-width: 1023px) {
  .callout {
    width: 100%;
    max-width: 450px;
    padding: 30px;

    h2 {
      font-size: 30px;
      padding: 50px 0 30px;
    }

    p {
      width: 100%;
      padding: 0;
      color: $darkGrey;
      font-size: 14px;
      line-height: 1.6;
    }
  }
}
