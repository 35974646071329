@import 'reset';
@import 'typography';
@import 'variables';

html,
body,
#root {
  min-height: 100vh;
  background: white;
  color: $almostBlack;
  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1.section-title {
  color: $darkGrey;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.36px;
  margin-bottom: 10px;
}
