@import '../styles/variables';

.legal {
  padding-top: 300px;
  min-height: 100vh;
  background-color: $lightGrey;
}
.content {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 80px 100px 80px;

  a {
    color: #1e1e30 !important;
  }

  h4 {
    color: $primaryColor;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.47px;
    text-transform: uppercase;
  }

  h1,
  h2 {
    color: $almostBlack;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.2;
    margin: 20px 0;
  }

  p {
    color: $darkGrey;
    font-size: 20px;
    line-height: 38px;
    padding-right: 70px;

    strong {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1023px) {
  .content {
    flex-wrap: wrap;
    padding: 30px;
  }
  .legal {
    height: unset;
    width: 100%;
    padding: 170px 20px;

    h4 {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 30px;
      line-height: 1.2;
      margin: 10px 0;
    }

    p {
      font-size: 14px;
      line-height: 1.6;
      padding-right: 0px;
    }
  }
}
