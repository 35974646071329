.EKG {
  width: 100%;
  text-align: center;
  padding: 300px 0 0;
}

.lineWrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

.graphPaper {
  height: 430px;
  width: 100%;
  opacity: 0.4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239b9a9c' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 12%;
  background-position: center;
}

.line {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.lineMask {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  max-width: 1600px;
  width: 75%;

  div {
    max-width: 1600px;
    width: 100%;
  }
}

.graphGradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  top: 0;
  left: 0;
}

@media screen and (max-width: 1023px) {
  .EKG {
    width: 100%;
    text-align: center;
    padding: 200px 0 0;
  }

  .graphPaper {
    height: 200px;
    opacity: 0.8;
    background-size: 20%;
  }

  .line {
    padding: 10px;
    width: 200%;
    height: 200%;
    transform: translateX(-30%);
  }

  .lineMask {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: visible;
    max-width: 100%;
    width: 125%;

    div {
      max-width: 1600px;
      width: 80%;
    }
  }

  .graphGradient {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 1) 100%
    );
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .line {
    width: 300%;
    height: 300%;
  }

  .graphPaper {
    background-size: 30%;
  }
}
