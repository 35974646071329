@import "../../styles/variables";

.button {
  color: white;
  height: 44px;
  font-size: 18px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  min-width: 120px;
  padding: 0 20px;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.primary {
  background: $primaryColor;
}

.secondary {
  border: 1px solid white;

  &.alt {
    border-color: $primaryColor;
    color: $primaryColor;
  }
}
