@import "../styles/variables";

.threeboxes {
  display: flex;
  justify-content: space-evenly;
  background-color: $lightGrey;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 220px 0;
}

.wrap {
  background-color: $lightGrey;
}

@media screen and (max-width: 1023px) {
  .threeboxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $lightGrey;
    width: 100%;
    margin: 0 auto;
    padding: 50px 30px 120px;
  }
}
