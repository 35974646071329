@import '../styles/variables';

.footer {
  display: flex;
  background-color: #10101b;
  align-items: center;
  height: 500px;
}

.content {
  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  section {
    h5 {
      color: $primaryColor;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.36px;
      margin-bottom: 10px;
    }

    ul {
      li {
        color: $accent;

        a,
        span {
          display: block;
          text-decoration: none;
          padding: 8px 0;
          color: currentColor;

          &:visited {
            color: currentColor;
          }
        }
      }
    }

    &:last-child {
      h5 {
        margin-bottom: 16px;
      }
      li {
        margin-bottom: 10px;
      }
    }
  }

  .signUpInput {
    width: 350px;
    margin: 20px 20px 0 0;
  }

  .logo {
    svg {
      width: 110px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .content {
    flex-wrap: wrap;

    section {
      &:last-child {
        margin-top: 40px;
        flex-basis: 100%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    align-items: center;
    height: unset;
  }

  .content {
    flex-wrap: wrap;
    padding: 100px 30px;

    section {
      flex-basis: 60%;

      h5 {
        margin-bottom: 30px;
      }

      ul {
        margin-bottom: 100px;
        li {
          color: $accent;

          a {
            display: block;
            text-decoration: none;
            padding: 15px 0;
            color: currentColor;

            &:visited {
              color: currentColor;
            }
          }
        }
      }

      &:first-child {
        flex-basis: 100%;
      }

      &:nth-child(3) {
        flex-basis: 40%;
      }

      &:last-child {
        flex-basis: 100%;

        h5 {
          margin-bottom: 16px;
        }

        li {
          margin-bottom: 10px;

          button {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }

    .signUpInput {
      width: 100%;
      max-width: 350px;
      margin: 30px 20px 30px 0;
    }

    .logo {
      margin-bottom: 60px;
      svg {
        width: 110px;
      }
    }
  }
}
