@import '../styles/variables';

.contactUs {
  display: flex;
  padding-top: 170px;
  padding-bottom: 30px;
  align-items: flex-start;
  justify-content: center;
  background-color: $lightGrey;

  h2 {
    color: $almostBlack;
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 10px;
  }
}

.formModal {
  width: 770px;
  // height: 606px;
  background-color: white;
  padding: 30px 60px;
  box-shadow: 0px 10px 40px 5px rgba(133, 165, 230, 0.2);

  form {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;

    label {
      flex: 1;
      margin-right: 60px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.sidebar {
  margin-left: 160px;

  h4 {
    color: $darkGrey;
    font-size: 24px;
    font-weight: 900;
    margin: 30px 0 15px;
  }

  a {
    display: inline-block;
    color: $darkGrey;
    font-size: 20px;
    line-height: 28px;
    text-decoration: none;
    margin-bottom: 30px;
  }
}

.submitButton {
  margin-top: 30px;
  width: 100%;
}

.thankYouMessage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 50px;
  }

  p {
    font-size: 24px;
    margin: 40px 0 60px;
    max-width: 375px;
    text-align: center;
    line-height: 1.4;
  }
}

.thankYouButton {
  padding: 0 60px;
}

@media screen and (max-width: 1023px) {
  .contactUs {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: unset;
    width: 100%;
    padding: 170px 20px;

    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .formModal {
    width: 100%;
    height: unset;
    padding: 30px;

    form {
      display: flex;
      flex-direction: column;
    }

    .row {
      display: flex;
      flex-direction: column;

      label {
        margin-right: 0px;
      }
    }
  }

  .sidebar {
    margin-left: 0px;

    h4 {
      color: $darkGrey;
      font-size: 24px;
      font-weight: 900;
      margin: 50px 0 15px;
    }

    a {
      display: inline-block;
      color: $darkGrey;
      font-size: 20px;
      margin-bottom: 0px;
    }
  }

  .submitButton {
    margin-top: 30px;
    width: 100%;
  }

  .thankYouMessage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 50px;
    }

    p {
      font-size: 24px;
      margin: 40px 0 60px;
      max-width: 375px;
      text-align: center;
      line-height: 1.4;
    }
  }

  .thankYouButton {
    padding: 0 60px;
  }
}
