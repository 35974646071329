.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.buttonWrapper {
  a {
    cursor: pointer;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}

.logoWrapper {
  transform: translateY(50%);
}

@media screen and (max-width: 1023px) {
  .buttonWrapper {
    display: none;
  }
  .logoWrapper {
    transform: translateY(0%);
  }
  .wrapper {
    padding: 60px 40px 30px;
  }
}
