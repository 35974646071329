.wrapper {
  width: 100%;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  img {
    width: 100%;
  }
}

.aspect {
  position: relative;
  padding-bottom: 62.5%;
  width: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 80px;
}

.gradient {
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(45, 47, 65, 0.9),
    rgba(45, 47, 65, 0.4)
  );
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.videoWrapper,
.mobileHero {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video {
  width: 100%;
  transform: scale(1.25);
}

.text {
  position: relative;
  width: 650px;
  color: white;

  h1 {
    font-size: 70px;
    font-weight: 900;
    margin-bottom: 40px;
    line-height: 1.16;
  }

  p {
    font-size: 22px;
    line-height: 1.6;
    padding-right: 30px;
    margin-bottom: 50px;
  }
}

.mobileHero {
  display: none;
}

.heroImage {
  position: absolute;
  max-width: 400px;
  width: 30%;
  right: 60px;
  bottom: -100px;
  filter: drop-shadow(-50px 50px 50px rgba(0, 0, 0, 0.5));
}

.appStoreLink {
  display: inline-block;
  font-size: 0;
  box-shadow: 0px 22px 34px 0px rgba(0, 0, 0, 0.27);

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 1439px) {
  .aspect {
    position: relative;
    padding-bottom: 80%;
    width: 100%;
  }

  .video {
    width: 100%;
    transform: scale(1.9);
  }

  .text {
    width: 60%;

    h1 {
      font-size: 50px;
      font-weight: 900;
      margin-bottom: 40px;
      line-height: 1.16;
    }

    p {
      font-size: 22px;
      line-height: 1.6;
      padding-right: 30px;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .aspect {
    min-height: unset;
    padding-bottom: 0;
  }

  .content {
    padding: 200px 30px 100px;
    justify-content: flex-start;
    position: relative;
  }

  .videoWrapper {
    display: none;
  }

  .mobileHero {
    display: block;
    background-size: cover;
    background-position: 40% 50%;
  }

  .gradient {
    background: linear-gradient(
      to top,
      rgba(45, 47, 65, 0.3),
      rgba(45, 47, 65, 0.2)
    );
  }

  .text {
    width: 90%;

    h1 {
      font-size: 35px;
      margin-bottom: 40px;
      line-height: 1.16;
    }

    p {
      font-size: 17px;
      padding-right: 5%;
      margin-bottom: 50px;
    }
  }

  .heroImage {
    display: none;
  }
}
