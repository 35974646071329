@import "../../styles/variables";

.inputBase {
  appearance: none;
  border: none;
  height: 44px;
  border: 1px solid transparent;

  &.hasError {
    color: $errorRed;
    border-color: $errorRed;
  }
}

.primaryInput {
  composes: inputBase;
  background: $almostBlack;
  color: $accent;
  font-size: 15px;
  font-weight: 300;
  padding: 0 20px;
}

.secondaryLabel {
  display: block;
  margin-top: 25px;
  position: relative;

  div {
    color: $darkGrey;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 5px;
  }

  small {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(16px);
    color: $errorRed;
    font-size: 14px;
  }
}

.secondaryInput {
  composes: inputBase;

  height: 60px;
  border: 1px solid $mediumGrey;
  border-radius: 2px;
  color: $darkGrey;
  font-size: 16px;
  line-height: 28px;
  padding: 0 20px;
  width: 100%;
}

.secondaryTextArea {
  composes: secondaryInput;

  height: 130px;
  padding: 15px 20px;
}
