@import "../../styles/variables";

.smallbox {
  width: 440px;
  height: 420px;
  padding: 30px;
  display: flex;
  background-color: white;
  border-radius: 30px;
  flex-direction: column;
  box-shadow: 0px 10px 40px 5px rgba(133, 165, 230, 0.2);

  header {
    height: 55px;
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 24px;
    color: $darkGrey;
    padding-top: 20px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  p {
    font-size: 20px;
    color: $darkGrey;
    line-height: 28px;
  }
}

@media screen and (max-width: 1439px) {
  .smallbox {
    width: 290px;
    height: 290px;
    border-radius: 22px;
    margin: 20px 0;

    header {
      height: 45px;
      display: flex;
      align-items: center;
    }

    h2 {
      font-size: 18px;
      color: $darkGrey;
      padding-top: 20px;
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
      color: $darkGrey;
      line-height: 1.6;
    }
  }
}

@media screen and (max-width: 1023px) {
  .smallbox {
    width: 330px;
    height: 290px;
    border-radius: 22px;
    margin: 20px 0;

    header {
      height: 45px;
      display: flex;
      align-items: center;
      transform: scale(0.9);
    }

    h2 {
      font-size: 18px;
      color: $darkGrey;
      padding-top: 20px;
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      color: $darkGrey;
      line-height: 1.6;
    }
  }
}
