@font-face {
  font-family: "SF Pro Display";
  src: url("/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/fonts/SFProDisplay-Heavy.woff2") format("woff2"),
    url("/fonts/SFProDisplay-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/fonts/SFProDisplay-Light.woff2") format("woff2"),
    url("/fonts/SFProDisplay-Light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}
